.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
}

h1 {
  font-weight: bold;
  font-size: 4em;
  font-style: italic;
  margin: 0;
  padding: 0.5em;
}

@media (prefers-reduced-motion: no-preference) {
}

main {
  flex-grow: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.image {
  display: block;
}