.claim {
  margin: 30px;
}
pre {
  text-align: left;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
summary {
  cursor: pointer;
}
.layout {
  display: flex;
}
.selects {
  padding: 0 40px;
}
.selects > article:nth-child(3){
  min-height: 193px;
}
.pic {
  padding: 0 40px;
}
.pic > article {
  min-height: 723px;
}
.pic > article > p:first-child {
  min-height: 580px;
}
article {
  width: 560px;
}
.buttonAndMessage {
  display: flex;
  align-items: center;
}
.buttonAndMessage > p:nth-child(2) {
  flex-grow: 1;
  text-align: center;
}
